<template>
  <div class="row">
    <div class="col-12" v-if="hamla == ''">
        <div class="col-12">
            <br><br><br><br>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6 g text-right" v-if="hamla_1">
                <button class="btn btn-lg btn-primary" @click="hamla = '1'; gg()">
                    البشائر
                </button>
            </div>
            <div class="col-12 col-lg-6 g text-left" v-if="hamla_2">
                <button class="btn btn-lg btn-success" @click="hamla = '2'; gg()">
                    الميسر
                </button>
            </div>
        </div>
    </div>
    <div class="col-12 c g" v-if="hamla != ''">
        <div class="col-12 col-lg-5">
            <div class="card card-body">
                <div class="form-check g">
                <h5 class="form-check-label">
                    <input type="checkbox" class="form-check-input" name="" id="" :value="true" v-model="busses_check">
                    الغير موزعين على الحافلات
                </h5>
                </div>
                <div class="form-check g">
                <h5 class="form-check-label">
                    <input type="checkbox" class="form-check-input" name="" id="" :value="true" v-model="camps_check">
                    الغير موزعين على السكن
                </h5>
                </div>
                <div class="form-check g">
                <h5 class="form-check-label">
                    <input type="checkbox" class="form-check-input" name="" id="" :value="true" v-model="order_id_check">
                    مفترض ان يجتمعو في حافلة سوياً ({{ busers.length }})
                </h5>
                </div>
            </div>
        </div>
        <div class="card" v-if="order_id_check">
            <div class="card-header">
                <h5>
                    مفترض ان يجتمعو في حافلة سوياً
                </h5>
            </div>
            <div class="card-body">
                <div class="table-responsive col-12">
                    <table class="table table-hover table-sm table-bordered striped">
                        <thead>
                            <th>
                                الحاج
                            </th>
                            <th>
                                السبب
                            </th>
                            <th>
                                مفترض
                                يكون مع
                            </th>
                        </thead>
                        <tbody>
                            <template v-for="u in busers">
                                <tr :key="u._id">
                                    <td>
                                        <strong>{{ u.name }}</strong> (الحافلة {{ busses_names[u.bus_1] }})
                                    </td>
                                    <td>
                                        {{ u.reason }}
                                    </td>
                                    <td>
                                        <strong>{{ u.should_with }}</strong> (الحافلة {{ busses_names[u.bus_2] }})
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card" v-if="busses_check">
            <div class="card-header">
                <h5>
                    حجاج غير موزعين على الحافلات
                </h5>
            </div>
            <div class="card-body">
                <div class="table-responsive col-12">
                    <table class="table table-hover table-sm table-bordered striped">
                        <thead>
                            <th>
                                الحاج
                            </th>
                            <th>
                                الهاتف
                            </th>
                            <th>
                                رقم الحجز
                            </th>
                            <th>
                                نقطة الانطلاق
                            </th>
                            <th>
                                خيارات
                            </th>
                        </thead>
                        <tbody>
                            <template v-for="u in users">
                                <tr :key="u._id" v-if="u.bus_id == ''">
                                    <td>
                                        {{ u.name }}
                                    </td>
                                    <td>
                                        {{ u.phone }}
                                    </td>
                                    <td>
                                        {{ u.order_id }}
                                    </td>
                                    <td>
                                        {{ u.start_location }}
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-primary" @click="current = u;" v-b-modal.bus>
                                            نقل الى حافلة
                                        </button>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card" v-if="camps_check">
            <div class="card-header">
                <h5>
                    حجاج غير موزعين على السكن
                </h5>
            </div>
            <div class="card-body">
                <div class="table-responsive col-12">
                    <table class="table table-hover table-sm table-bordered striped">
                        <thead>
                            <th>
                                الحاج
                            </th>
                            <th>
                                الهاتف
                            </th>
                            <th>
                                رقم الحجز
                            </th>
                            <th>
                                سكن منى/مكة
                            </th>
                            <th>
                                سكن عرفات
                            </th>
                        </thead>
                        <tbody>
                            <template v-for="u in users">
                                <tr :key="u._id" v-if="u.camp_id == '' || u.camp2_id == ''">
                                    <td>
                                        {{ u.name }}
                                    </td>
                                    <td>
                                        {{ u.phone }}
                                    </td>
                                    <td>
                                        {{ u.order_id }}
                                    </td>
                                    <td>
                                        <span class="text-success" v-if="u.camp_id != ''">
                                            <i class="fa fa-check"></i>
                                        </span>
                                        <span class="text-danger" v-if="u.camp_id == ''">
                                            <i class="fa fa-times"></i>
                                            &nbsp;
                                            <button class="btn btn-sm btn-primary" @click="current = u" v-b-modal.camp>
                                                نقل الى سكن
                                            </button>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="text-success" v-if="u.camp2_id != ''">
                                            <i class="fa fa-check"></i>
                                        </span>
                                        <span class="text-danger" v-if="u.camp2_id == ''">
                                            <i class="fa fa-times"></i>
                                            &nbsp;
                                            <button class="btn btn-sm btn-primary" @click="current = u" v-b-modal.camp2>
                                                نقل الى سكن
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <b-modal id="bus" title="نقل" hide-footer>
            <div class="form-group">
            <label for="">نقل الى</label>
            <select class="form-control" v-model="current.bus_id">
                <option value="">-- اختر --</option>
                <template v-for="bus in busses">
                    <option :value="bus._id" v-if="bus.hamla == current.hamla && (bus.mix || !bus.mix && current.gender == 'ذكر') && bus.start_location == current.start_location" :key="bus._id">
                        {{ bus.title }} - {{ bus.mix ? 'عوائل' : 'رجال' }} ({{ bus.limit - bus.users.length == 0 ? 'مكتملة' : (bus.limit - bus.users.length > 0 ? 'متبقي ' + (bus.limit - bus.users.length) : 'هناك ' + ((bus.limit - bus.users.length).toString().replace('-', "")) + ' زيادة') }})
                    </option>
                </template>
            </select>
            </div>
            <div class="col-12 text-center">
                <button class="btn btn-primary" @click="save_current()">
                    نقل الان
                </button>
            </div>
        </b-modal>
        <b-modal id="camp2" title="نقل" hide-footer>
            <div class="form-group">
            <label for="">نقل الى</label>
            <select class="form-control" v-model="current.camp2_id">
                <option value="">-- اختر --</option>
                <template v-for="camp in camps">
                    <option :value="camp._id" v-if="camp.hamla == current.hamla && camp.type == '2' && (current.gender == 'ذكر' && camp.gender == 1 || current.gender == 'انثى' && camp.gender == 2)" :key="camp._id">
                        {{ camp.title }} - {{ camp.gender == 1 ? 'رجال' : 'نساء' }} ({{ camp.limit - camp.users.length == 0 ? 'مكتملة' : (camp.limit - camp.users.length > 0 ? 'متبقي ' + (camp.limit - camp.users.length) : 'هناك ' + ((camp.limit - camp.users.length).toString().replace('-', "")) + ' زيادة') }})
                    </option>
                </template>
            </select>
            </div>
            <div class="col-12 text-center">
                <button class="btn btn-primary" @click="save_current()">
                    نقل الان
                </button>
            </div>
        </b-modal>
        <b-modal id="camp" title="نقل" hide-footer>
            <div class="form-group">
            <label for="">نقل الى</label>
            <select class="form-control" v-model="current.camp_id">
                <option value="">-- اختر --</option>
                <template v-for="camp in camps">
                    <option :value="camp._id" v-if="camp.hamla == current.hamla && camp.type == '1' && (current.gender == 'ذكر' && camp.gender == 1 || current.gender == 'انثى' && camp.gender == 2)" :key="camp._id">
                        {{ camp.title }} - {{ camp.gender == 1 ? 'رجال' : 'نساء' }} ({{ camp.limit - camp.users.length == 0 ? 'مكتملة' : (camp.limit - camp.users.length > 0 ? 'متبقي ' + (camp.limit - camp.users.length) : 'هناك ' + ((camp.limit - camp.users.length).toString().replace('-', "")) + ' زيادة') }})
                    </option>
                </template>
            </select>
            </div>
            <div class="col-12 text-center">
                <button class="btn btn-primary" @click="save_current()">
                    نقل الان
                </button>
            </div>
        </b-modal>
    </div>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            busers: [],
            bus_id: "",
            camp_id: "",
            camp2_id: "",
            user: JSON.parse(localStorage.getItem("user")),
            users: [],
            busses_names: {},
            current: {},
            busses: [],
            camps: [],
            hamla: "",
            busses_check: false,
            camps_check: false,
            order_id_check: false,
            hamla_2: checkPer("hamla_2"),
            hamla_1: checkPer("hamla_1")
        }
    },
    created(){
        
    },
    methods: {
        save_current(){
            var g = this;
            $.post(api + '/admin/users/save', {
                jwt: g.user.jwt,
                id: g.current._id,
                user: g.current
            }).then(function(a){
                g.gg();
            })
        },
        gg(){
            var g = this;
            $.post(api + '/admin/users/not-arranged', {
                jwt: g.user.jwt,
                hamla: g.hamla
            }).then(function(r){
                g.users = r.response
            })
            $.post(api + '/admin/users/should_together', {
                jwt: g.user.jwt,
                hamla: g.hamla
            }).then(function(r){
                g.busers = r.response
            })
            $.post(api + '/admin/busses/list-with-users', {
                jwt: g.user.jwt,
                hamla: g.hamla
            }).then(function(r){
                g.busses = r.response
                g.busses.forEach(function(a){
                    g.busses_names[a._id] = a.title
                })
            })
            $.post(api + '/admin/camps/list-with-users', {
                jwt: g.user.jwt,
                hamla: g.hamla
            }).then(function(r){
                g.camps = r.response
            })
        }
    }
}
</script>

<style>

</style>